import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import Home from './JS/Home';
import AboutUS from './JS/AboutUS';
import Contact from './JS/Contact';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/AboutUS" element={<AboutUS />} />
          <Route path="/Contact" element={<Contact />} />
          {/* Add any other routes here */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
