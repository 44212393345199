import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin, FaAppStoreIos, FaGooglePlay } from 'react-icons/fa';
import './AboutUS.css';

const AboutUS = () => {
    return (
        <div className="home">
            <header>
                <Link to="/" className="no-underline">
                    <div className="header-left">
                        <img src="OmunifyLogoOrange.png" alt="Logo" className="logo" />
                        <h1 className="site-name">Omunid</h1>
                    </div>
                </Link>
                <nav className="header-right">
                    <Link to="/">Home</Link>
                    <Link to="/AboutUS">About Us</Link>
                    <Link to="/contact">Contact</Link>
                </nav>
            </header>
            <main>
                <div className="image-container">
                    <img src="A-1.png" alt="Introducting omuind" className="second-image" />
                </div>
                <div className="image-container">
                    <img src="A-2.png" alt="Introducting omuind" className="second-image" />
                </div>
                <div className="image-container">
                    <img src="A-3.png" alt="Introducting omuind" className="second-image" />
                </div>


                <footer className="footer ">
                    <div className="footer-left">
                        <h2>Omunid</h2>
                        <p>2023 © Omunid LLC</p>
                        <p>All rights reserved.</p>
                        <div className="store-icons">
                            <a href="https://play.google.com/store/apps/details?id=com.omunid.Omunid" target="_blank" rel="noopener noreferrer">
                                <FaGooglePlay size={30} />
                            </a>
                            <a href="https://apps.apple.com/us/app/omunid/id6462015743" target="_blank" rel="noopener noreferrer">
                                <FaAppStoreIos size={30} />
                            </a>
                        </div>
                    </div>
                    <div className="footer-middle footer-middle-left">
                        <Link to="/">Home</Link>
                        <Link to="/AboutUS">About Us</Link>
                        <Link to="/contact">Contact us</Link>
                    </div>
                    <div className="footer-middle footer-middle-right">
                        <a href="/Privacy.html">Privacy Policy</a>
                        <a href="/Terms.html">Terms of Use</a>

                    </div>
                    <div className="social-icons">
                        <a href="https://www.instagram.com/joinomunid/" target="_blank" rel="noopener noreferrer">
                            <FaInstagram size={30} />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=100093152905905" target="_blank" rel="noopener noreferrer">
                            <FaFacebook size={30} />
                        </a>
                        <a href="https://www.linkedin.com/company/omunid/" target="_blank" rel="noopener noreferrer">
                            <FaLinkedin size={30} />
                        </a>
                    </div>
                </footer>


            </main >

        </div >
    );
}
export default AboutUS;
