import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin, FaAppStoreIos, FaGooglePlay } from 'react-icons/fa';
import './Home.css';

const Home = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);
    const [isIOS, setIsIOS] = useState(false);
    const [isSmallDevice, setIsSmallDevice] = useState(false);

    useEffect(() => {
        // Checking the platform
        const userAgent = navigator.userAgent || navigator.vendor;
        if (/android/i.test(userAgent)) {
            setIsAndroid(true);
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setIsIOS(true);
        }

        // Check if screen is of a mobile device or small screen
        const screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        setIsMobile(screenWidth < 768);
        setIsSmallDevice(screenWidth < 1024); // Checking for devices less than 14 inches

        const script = document.createElement('script');
        script.src = "//embed.typeform.com/next/embed.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    // For Mobile Devices
    if (isMobile) {
        if (isAndroid) {
            // Redirect to Play Store
            window.location.href = "https://play.google.com/store/apps/details?id=com.omunid.Omunid";
        } else if (isIOS) {
            // Redirect to App Store
            window.location.href = "https://apps.apple.com/us/app/omunid/id6462015743";
        } else {
            // Generic message for other mobile devices
            return (
                <div>
                    <h1>Download our app for a better experience.</h1>
                </div>
            );
        }
    }

    // Preventing certain JS behavior for small devices 
    if (isSmallDevice) {
        return null; // or some kind of placeholder, if desired.
    }



    return (
        <div className="home">
            <header>
                <Link to="/" className="no-underline">
                    <div className="header-left">
                        <img src="OmunifyLogoOrange.png" alt="Logo" className="logo" />
                        <h1 className="site-name">Omunid</h1>
                    </div>
                </Link>

                <nav className="header-right">
                    <Link to="/">Home</Link>
                    <Link to="/AboutUS">About Us</Link>
                    <Link to="/Contact">Contact</Link>
                </nav>
            </header>
            <main>
                <div className="content">

                    <div className="text-section">
                        <p className="text-small">WANT TO MAKE A DIFFERENCE AT YOUR UNIVERSITY?</p>
                        <h2 className="text-large">Connecting Campus Communities</h2>
                        <p className="text-medium">Leveling up student organizations with everything you need, all-in-one platform.</p>
                    </div>
                    <img src="first.png" alt="omunid first" className="first-image" />
                </div>
                <div className="image-container">
                    <img src="Second.png" alt="Introducting omuind" className="second-image" />
                </div>


                <div className="key-features-section">
                    <div className="key-features-section-1" />
                    <h1 className="key-features-header">Key Features</h1>
                    <p className="key-features-subheader">Omunid helps university student organizations grow and develop,</p>
                    <p className="key-features-subheader">here are just a few ways we help you.</p>
                    <div className="box3D">
                        <span style={{ "--i": "1" }}><img src="Six.png" className="feature-image" alt="Feature 1" /></span>
                        <span style={{ "--i": "2" }}><img src="One.png" className="feature-image" alt="Feature 2" /></span>
                        <span style={{ "--i": "3" }}><img src="Two.png" className="feature-image" alt="Feature 3" /></span>
                        <span style={{ "--i": "4" }}><img src="Three.png" className="feature-image" alt="Feature 4" /></span>
                        <span style={{ "--i": "5" }}><img src="Four.png" className="feature-image" alt="Feature 1" /></span>
                        <span style={{ "--i": "6" }}><img src="five.png" className="feature-image" alt="Feature 1" /></span>
                        <span style={{ "--i": "7" }}><img src="Two.png" className="feature-image" alt="Feature 1" /></span>
                        <span style={{ "--i": "8" }}><img src="Six.png" className="feature-image" alt="Feature 2" /></span>

                    </div>
                    {/* Improved */}


                    <div className="comparison-section">

                        <div className="comparison-column-1">
                            <h2 className="comparison-title">MAXIMIZE</h2>
                            <h3 className="comparison-subtitle">Funds</h3>
                            <p className="comparison-text">Track all your funds with every transaction and payment to stay up to date on your financials. Know where your money is going at all times. Centralized payment system coming soon to Omunid.</p>
                        </div>
                        <div className="comparison-column-2">
                            <h2 className="comparison-title">EFFORTLESSLY</h2>
                            <h3 className="comparison-subtitle">Engage</h3>
                            <p className="comparison-text">Keep in touch with members by announcing events, sharing updates, and messaging them directly. Keep track of stats, manage emails, and access contact info for streamlined coordination and engagement.</p>
                        </div>

                    </div>
                    <div className="image-container">
                        <img src="Third.png" alt="Download omuind" className="third-image" />
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `
            <div data-tf-widget="SmavtTWV" data-tf-opacity="100" data-tf-iframe-props="title=BETA LIST" data-tf-transitive-search-params data-tf-medium="snippet" style="width:80%;height:500px;margin-left:auto;margin-right:auto; margin-top: 200px;"></div>
        `,
                        }}
                    />

                    <div className="image-container">
                        <img src="Forth.png" alt="Join omuind beta list" className="forth-image" />
                    </div>


                    <footer className="footer ">
                        <div className="footer-left">
                            <h2>Omunid</h2>
                            <p>2023 © Omunid LLC</p>
                            <p>All rights reserved.</p>
                            <div className="store-icons">
                                <a href="https://play.google.com/store/apps/details?id=com.omunid.Omunid" target="_blank" rel="noopener noreferrer">
                                    <FaGooglePlay size={30} />
                                </a>
                                <a href="https://apps.apple.com/us/app/omunid/id6462015743" target="_blank" rel="noopener noreferrer">
                                    <FaAppStoreIos size={30} />
                                </a>
                            </div>
                        </div>
                        <div className="footer-middle footer-middle-left">
                            <Link to="/">Home</Link>
                            <Link to="/AboutUS">About Us</Link>
                            <Link to="/Contact">Contact us</Link>
                        </div>
                        <div className="footer-middle footer-middle-right">
                            <a href="/Privacy.html">Privacy Policy</a>
                            <a href="/Terms.html">Terms of Use</a>

                        </div>
                        <div className="social-icons">
                            <a href="https://www.instagram.com/joinomunid/" target="_blank" rel="noopener noreferrer">
                                <FaInstagram size={30} />
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=100093152905905" target="_blank" rel="noopener noreferrer">
                                <FaFacebook size={30} />
                            </a>
                            <a href="https://www.linkedin.com/company/omunid/" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin size={30} />
                            </a>
                        </div>
                    </footer>
                </div>

            </main >

        </div >
    );
}

export default Home;
