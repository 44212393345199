import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin, FaAppStoreIos, FaGooglePlay } from 'react-icons/fa';
import './Contact.css';

const Contact = () => {
    const buttonHTML = `
    <button data-tf-slider="kmhx9hNt" data-tf-position="right" data-tf-opacity="100" 
    data-tf-iframe-props="title=Contact Us" data-tf-transitive-search-params data-tf-medium="snippet" 
    style="all:unset;font-family:Helvetica,Arial,sans-serif;display:inline-block;max-width:100%;
    white-space:nowrap;overflow:hidden;text-overflow:ellipsis;
    background-color:#f87b45;color:#fff;
    font-size:20px;border-radius:25px;padding:0 33px;font-weight:bold;height:50px;cursor:pointer;
    line-height:50px;text-align:center;margin:0;text-decoration:none;">Contact Us</button>
    `;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//embed.typeform.com/next/embed.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);
    return (
        <div className="home">
            <header>
                <Link to="/" className="no-underline">
                    <div className="header-left">
                        <img src="OmunifyLogoOrange.png" alt="Logo" className="logo" />
                        <h1 className="site-name">Omunid</h1>
                    </div>
                </Link>
                <nav className="header-right">
                    <Link to="/">Home</Link>
                    <Link to="/AboutUS">About Us</Link>
                    <Link to="/Contact">Contact</Link>
                </nav>
            </header>
            <main>

                <div className="content">
                    <img className="big-image" src="contact.png" alt="Your Image" />

                    <div className="contact-form">
                        <h1>Talk to Us</h1>
                        <p>Need help? Want to join us?</p>
                        <div dangerouslySetInnerHTML={{ __html: buttonHTML }} />
                    </div>
                </div>

                <footer className="footer ">
                    <div className="footer-left">
                        <h2>Omunid</h2>
                        <p>2023 © Omunid LLC</p>
                        <p>All rights reserved.</p>
                        <div className="store-icons">
                            <a href="https://play.google.com/store/apps/details?id=com.omunid.Omunid" target="_blank" rel="noopener noreferrer">
                                <FaGooglePlay size={30} />
                            </a>
                            <a href="https://apps.apple.com/us/app/omunid/id6462015743" target="_blank" rel="noopener noreferrer">
                                <FaAppStoreIos size={30} />
                            </a>
                        </div>
                    </div>
                    <div className="footer-middle footer-middle-left">
                        <Link to="/">Home</Link>
                        <Link to="/AboutUS">About Us</Link>
                        <Link to="/Contact">Contact us</Link>
                    </div>
                    <div className="footer-middle footer-middle-right">
                        <a href="/Privacy.html">Privacy Policy</a>
                        <a href="/Terms.html">Terms of Use</a>

                    </div>
                    <div className="social-icons">
                        <a href="https://www.instagram.com/joinomunid/" target="_blank" rel="noopener noreferrer">
                            <FaInstagram size={30} />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=100093152905905" target="_blank" rel="noopener noreferrer">
                            <FaFacebook size={30} />
                        </a>
                        <a href="https://www.linkedin.com/company/omunid/" target="_blank" rel="noopener noreferrer">
                            <FaLinkedin size={30} />
                        </a>
                    </div>
                </footer>


            </main >

        </div >
    );
}
export default Contact;
